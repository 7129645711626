import store from '@/store'

import { getAsString } from '@/functions/env'

export const titleDefault = 'HosPanel'

const VUE_APP_TRAY_COMPONENT_NAME = getAsString('VUE_APP_TRAY_COMPONENT_NAME')



export const routes = [
	{
		path: '/',
		name: 'start',
		component: () =>
			import(/* webpackChunkName: "offline" */ '@/views/panel/Start.vue'),
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () =>
			import(/* webpackChunkName: "offline" */ '@/views/Login.vue'),
		meta: {
			layout: 'AppLayoutLogin',
			requiresAuth: false,
			requirePublicClasses: true,
			title: `${titleDefault} - Logowanie`,
		},
	},
	{
		path: '/recover-password',
		name: 'recover-password',
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/RecoverPassword.vue'
			),
		meta: {
			layout: 'AppLayoutLogin',
			requiresAuth: false,
			title: `${titleDefault} - Odzyskiwanie hasła`,
		},
	},
	{
		path: '/stays',
		name: 'stays',
		component: () =>
			import(/* webpackChunkName: "offline" */ '@/views/panel/Stays.vue'), //TODO StaysAndNotes.vue
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
			title: `${titleDefault} - Pobyty`,
		},
	},
	{
		path: '/stay/:id',
		name: 'stay',
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/panel/stay/index.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Pobyt`,
		},
	},
	{
		path: '/stay/:id/examinations',
		name: 'examinations',
		redirect: (to) => {
			return { ...to, name: 'examinations.examinations-timeline' }
		},
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/panel/stay/examinations/index.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Zlecone badania`,
		},
		children: [
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Zlecone badania - lista`,
				},
				name: 'examinations.examinations-timeline',
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "offline" */ '@/views/panel/stay/examinations/ExaminationsTimeline.vue'
					),
			},
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Zlecone badania - kompakt`,
				},
				name: 'examinations.examinations-compact',
				path: 'compact',
				component: () =>
					import(
						/* webpackChunkName: "offline" */ '@/views/panel/stay/examinations/ExaminationsCompact.vue'
					),
			},
		],
	},
	{
		path: '/stay/:id/stats/:param',
		name: 'statistics',
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/panel/stay/Parameter.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Paramatry - statystyki`,
		},
	},
	{
		path: '/stay/:id/params',
		name: 'params',
		redirect: (to) => {
			const role = store.getters['CurrentUser/userRole']
			
			if(role === 'Personel_ZWR')
				return { ...to, name: 'params.params-compact' }
		
			return { ...to, name: 'params.params-latest' }
		},
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/panel/stay/Parameters.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Parametry`,
		},
		children: [
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Parametry - Ostatnie`,
				},
				name: 'params.params-latest',
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "offline" */ '@/components/stay/params/ParamsLatest.vue'
					),
			},
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Parametry - Lista`,
				},
				name: 'params.params-compact',
				path: 'compact',
				component: () =>
					import(
						/* webpackChunkName: "offline" */ '@/components/stay/params/ParamsCompact.vue'
					),
			},
		],
	},
	{
		path: '/stay/:id/drug-applications',
		name: 'drug-applications',
		redirect: (to) => {
			return { ...to, name: 'drug-applications.drug-applications-compact' }
		},
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/panel/stay/drug_applications'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Leki`,
		},
		children: [
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Leki - lista`,
				},
				name: 'drug-applications.drug-applications',
				path: 'timeline',
				component: () =>
					import(
						/* webpackChunkName: "offline" */ '@/views/panel/stay/drug_applications/DrugApplicationsTimeline'
					),
			},
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Leki - kompakt`,
				},
				name: 'drug-applications.drug-applications-compact',
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "offline" */ '@/views/panel/stay/drug_applications/DrugApplicationsCompact'
					),
			},
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Leki - Taca`,
				},
				name: 'drug-applications.drug-applications-tray',
				path: 'tray',
				component: () =>
					import(
						/* webpackChunkName: "offline" */ `@/views/panel/stay/drug_applications/${VUE_APP_TRAY_COMPONENT_NAME}`
					),
			},
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Leki - Taca`,
				},
				name: 'drug-applications.drug-applications-tray-by-drug',
				path: 'tray-by-drug',
				component: () =>
					import(
						/* webpackChunkName: "offline" */ `@/views/panel/stay/drug_applications/DrugApplicationsTrayByDrug`
					),
			},
			{
				meta: {
					layout: 'AppLayoutPatient',
					requiresAuth: true,
					title: `${titleDefault} - Leki - Taca`,
				},
				name: 'drug-applications.drug-applications-tray-by-time',
				path: 'tray-by-time',
				component: () =>
					import(
						/* webpackChunkName: "offline" */ `@/views/panel/stay/drug_applications/DrugApplicationsTrayByTime`
					),
			},
		],
	},
	{
		path: '/stay/:id/notes',
		name: 'notes',
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/panel/stay/Notes.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Notatki`,
		},
	},
	{
		path: '/stay/:id/treatments',
		name: 'treatments',
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/panel/stay/Treatments.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Dane opisowe`,
		},
	},
	{
		path: '/stay/:id/summary',
		name: 'summary',
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/panel/stay/Summary.vue'
			),
		meta: {
			layout: 'AppLayoutPatient',
			requiresAuth: true,
			title: `${titleDefault} - Podsumowanie z 24h`,
		},
	},
	// {
	// 	path: '/stay/:id/news2',
	// 	name: 'statistics-news2',
	// 	component: () =>
	// 		import(
	// 			/* webpackChunkName: "offline" */ '@/views/panel/stay/News2.vue'
	// 		),
	// 	meta: {
	// 		layout: 'AppLayoutPatient',
	// 		requiresAuth: true,
	// 	},
	// },
	{
		path: '/zwr',
		name: 'zwr',
		component: () =>
			import(/* webpackChunkName: "zwr" */ '@/views/panel/Zwr.vue'),
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
			// requirePublicClasses: true,
			// requireAuthenticatedClasses: true,
			requiresRoleZWR: true,
			title: `${titleDefault} - ZWR`,
		},
	},
	{
		path: '/tools',
		name: 'tools',
		redirect: (to) => {
			return { ...to, name: 'tools.sync-adv' }
		},
		component: () =>
			import(/* webpackChunkName: "tools" */ '@/views/Tools.vue'),
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
			title: `${titleDefault} - Database`,
		},
		children: [
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Tools - Sync`,
				},
				name: 'tools.sync-adv',
				path: '',
				component: () =>
					import(
						/* webpackChunkName: "tools" */ '@/views/tools/Sync'
					),
			},
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Tools - Sync Bulk`,
				},
				name: 'tools.sync-bulk',
				path: 'sync-bulk',
				component: () =>
					import(
						/* webpackChunkName: "tools" */ '@/views/tools/SyncBulk'
					),
			},
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Tools - Preview`,
				},
				name: 'tools.preview',
				path: 'preview',
				component: () =>
					import(
						/* webpackChunkName: "tools" */ '@/views/tools/Preview'
					),
			},
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Tools - Simulations`,
				},
				name: 'tools.simulations',
				path: 'simulations',
				component: () =>
					import(
						/* webpackChunkName: "tools" */ '@/views/tools/Simulations'
					),
			},
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Tools - Preview`,
				},
				name: 'tools.batch_size-test',
				path: 'batch-size-test',
				component: () =>
					import(
						/* webpackChunkName: "tools" */ '@/views/tools/BatchSizeTest'
					),
			},	
			{
				meta: {
					layout: 'AppLayoutStart',
					requiresAuth: true,
					title: `${titleDefault} - Tools - Camera`,
				},
				name: 'tools.camera',
				path: 'camera',
				component: () =>
					import(
						/* webpackChunkName: "tools" */ '@/views/tools/Camera'
					),
			},
		]
	},
	{
		path: '/sync',
		name: 'database-sync',
		component: () =>
			import(
				/* webpackChunkName: "offline" */ '@/views/DatabaseSyncSimple.vue'
			),
		meta: {
			layout: 'AppLayoutStart',
			requiresAuth: true,
			requiresRoleZWR: false,
			title: `${titleDefault} - Synchronizacja`,
		},
	},
	{
		path: '/technical-break',
		name: 'technical-break',
		component: () =>
			import(/* webpackChunkName: "offline" */ '@/components/Error500'),
		meta: {
			layout: 'AppLayoutDefault',
			requiresAuth: false,
			title: `${titleDefault} - Przerwa techniczna`,
		},
	}
]

export default routes
