<template>
	<div id="main" class="d-flex justify-content-center align-items-center">
		<h1
			class="mr-3 pr-3 align-top border-right inline-block align-content-center"
		>
			{{ $t('Maintenace break') }}
		</h1>
		<div class="inline-block align-middle">
			<h2 id="desc" class="font-weight-normal lead">
				{{ $t(msg) }}
			</h2>
		</div>
	</div>
</template>

<script>
	import { fetchConfigInterval, getFromConfig } from '@/functions/config'
	export default {
		name: 'Error500',
		props: {
			msg: {
				type: String,
				default: 'Please wait for the end of the maintenance break'
			}
		},
		async mounted() {
			if(getFromConfig('TECHNICAL_BREAK'))
				await fetchConfigInterval(
					() => {
						return getFromConfig('TECHNICAL_BREAK')
					},
					10000,
					() => {
						if(this.$router)
							this.$router.push('/')
						else
							document.location.reload()
					}
				)
		}
	}
</script>

<style lang="scss" scoped>
	// @import '~bootstrap/scss/bootstrap.scss';
	#main {
		height: 100vh;
	}
</style>