import { isQuotaExceededError, quotaExceededErrorResolution } from '@/functions/error'
import { keys } from '@/functions/localStorage.js'
const { configKey } = keys

export const saveConfig = function (value=null) {
	try {
		if(!value) 
			return null
		localStorage.setItem(configKey, JSON.stringify(value))
	} catch (e) {
		if (isQuotaExceededError(e)) quotaExceededErrorResolution(e)
		else throw e
	}
}

export const getConfig = function () {
	return JSON.parse(localStorage.getItem(configKey) || '{}')
}

export const removeConfig = function () {
    localStorage.removeItem(configKey)
}

export const getFromConfig = function (key) {
    const config = getConfig()
    const obj = config[key]
    
    return obj || null
}

import { $classes } from '@/main'
import { isOnline } from '@/functions/network'

export const fetchConfig = async function () {
    try {
        await isOnline()

        if(!$classes?.ConfigItem?.get_all){
            await $classes.loadPublic()
        }
        
        if(!$classes?.ConfigItem?.get_all)
            throw new Error('No $classes.ConfigItem.get_all')
            
        const config = await $classes.ConfigItem.get_all()
        
        saveConfig(config)
    } catch (e) {
       console.warn(e) 
    }
}

export const fetchConfigInterval = async function (condition, interval, trigger = () => {}) {
    fetchConfig()
    const intervalId = setInterval(() => {
        if (condition()) {
            fetchConfig()
        } else {
            trigger()
            clearInterval(intervalId)
        }
    }, interval)
}