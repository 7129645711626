export const keys = {
    // auth, instance
    user: 'user',
    authEncryptedByUser: 'auth-encrypted',
    authEncryptedByCredentialId: 'auth-encrypted-2',
    
    // rxdb
    userPassHashKey: 'rxdb-encryption-usr',
    userPassHashDoubleKey: 'rxdb-encryption-usr-2',
    passwordEncryptedByUserKey: 'rxdb-encryption-usr-3',
    
    saHashDoubleKey: 'rxdb-encryption-sa',
    saHashTripleKey: 'rxdb-encryption-sa-2',
    passwordEncryptedBySaHashKey: 'rxdb-encryption-sa-3',
    
    credentialIdHashKey: 'rxdb-encryption-cr',
    credentialIdHashDoubleKey: 'rxdb-encryption-cr-2',
    passwordEncryptedByCredentalIdKey: 'rxdb-encryption-cr-3',
    credentialIdUserLoginHashKey: 'rxdb-encryption-cr-4',
    
    repoCommitHashKey: 'VUE_APP_COMMIT_HASH',
    
    cachedParams: 'calculated-params',
    pendingReports: 'pending-reports',
    lastSync: 'database-last-sync',
    
    encryptKey: 'DB_ENCRYPTION_ENABLED',
    logs: 'logs',
    dbLockKey: 'RxDB-db-remove-lock',
    
    trayChangesFlag: 'tray-changes-flag',
    
    keyPendingSync: 'rxdb-pending-sync-time',
    keyLastFullSync: 'rxdb-last-full-sync-time',
    
    errorResulution: 'error-resolution',
    
    userActivity: 'user-activity',
    
    configKey: 'gui-config',
}

export const clearCachedData = function () {
    console.debug(`[clearCachedData] usuwanie (mozliwych do wygenerowania przez gui) danych z cache. Klucz=${keys.cachedParams}`)
    localStorage.removeItem(keys.cachedParams)
}

export const clearAllLocalStorage = function () {
    console.debug(`[clearAllLocalStorage] usuwanie wszystkich danych z localStorage`)
    localStorage.clear()
}

export default keys